import React from 'react';
import styled from 'styled-components';
import Menu from '../components/Menu/menu';
import Footer from '../components/Footer/footer';
import Layout from "../components/Layout/layout"
import image from '../assets/images/background2.jpg'

const ShopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-image: ${({ image }) => `url(${image})`};
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
`;

const MenuWrapper = styled.div`
    display:flex;
    flex-direction: column;
    height: 10vh;
`


const ContentWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    height: 80vh;
    width: 100%;
`

const Content = styled.div`
    width: 70%;
    height: 75vh;
    /* overflow:auto; */
    /* padding: 30px; */
    /* background-color: rgba(0,0,0,0.8); */
    color: white;
`
// content-wrap
const ShopPage = () => (
    <Layout>
        <ShopWrapper image={image}>
            <>
                <MenuWrapper>
                    <Menu showHome />
                </MenuWrapper>
                <ContentWrapper>
                    <Content>
                    <iframe frameBorder="0" width="100%" height="100%" allowtransparency="true" scrolling="auto" src="https://boguszmusic.cupsell.pl/?iframe=1" title="Bogusz"></iframe>
                    </Content>
                </ContentWrapper>
                <Footer />
            </>
        </ShopWrapper>
    </Layout>
)

export default ShopPage;